.ant-select-item.ant-select-item-option{
  width: fit-content;
}
ant-select-dropdown.ant-select-dropdown-hidden.custom-dropdown.css-dev-only-do-not-override-19oi6yr.ant-select-dropdown-placement-bottomLeft{
  width: fit-content;
}
.custom-dropdown{
  width: fit-content!important;
}
body {
  margin: 0;
  font-family: 'Satoshi-Variable', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen';
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  scrollbar-width: auto;
  scrollbar-color: #7a7a7a #1c1c1e; /* previous #7a7a7a */
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background: #1c1c1e;
}

*::-webkit-scrollbar-thumb {
  background-color: #7a7a7a;
  border-radius: 10px;
  border: 3px solid #1c1c1e;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 !important;
}

.ant-tag {
  margin-inline-end: 0;
}

:where(.css-dev-only-do-not-override-11dba2k).ant-drawer .ant-drawer-body {
  padding: 18px;
}
:where(.css-dev-only-do-not-override-19oi6yr).ant-drawer .ant-drawer-body {
  overflow-x: hidden;
}
:where(.css-dev-only-do-not-override-1ux7a18) a {
  color: white;
  font-weight: bold;
}
:where(.css-dev-only-do-not-override-1ux7a18).ant-btn-primary {
  color: black;
  font-weight: bold;
  background-color: white;
}
/*:where(.css-dev-only-do-not-override-1ux7a18) .ant-btn-primary:hover {*/
/*    color: black;*/
/*    background-color: #C7C7C7;*/
/*}*/
:where(.css-dev-only-do-not-override-1ux7a18) a:hover {
  color: #c7c7c7;
}
:where(.css-dev-only-do-not-override-1ux7a18).ant-picker-dropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  font-weight: bolder;
  color: #c7c7c7;
  background: #7a7a7a;
}
:where(.css-dev-only-do-not-override-1ux7a18).ant-picker-dropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell
  .ant-picker-time-panel-cell-inner:hover {
  font-weight: bolder;
  color: #c7c7c7;
  background: rgba(255, 255, 255, 0.08);
}

:where(.css-dev-only-do-not-override-1ux7a18).ant-picker-dropdown
  .ant-picker-ranges {
  margin-top: 0px;
}
:where(.css-dev-only-do-not-override-1ux7a18).ant-picker
  .ant-picker-input
  > input {
  color: rgba(255, 255, 255, 0.85);
}

.ant-layout,
.ant-layout-sider-light,
.ant-layout-sider-trigger,
.ant-layout-footer,
.ant-menu-light {
  /* background: #ECECEC !important; */
}

.ant-avatar {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.ant-avatar-group > *:not(:first-child) {
  margin-inline-start: -4px;
}

.disable-scroll {
  overflow-y: hidden;
}

.ant-popover-inner {
  padding: 0 !important;
}

:focus {
  outline: none;
}
